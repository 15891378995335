.app {
    font-family: sans-serif;
    /* text-align: center; */
    margin-bottom: 5rem;
}

.wrapper {
    margin-left: 20rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.timeline {
    height: 300px;
    width: 5px;
    background-color: #e5e5e5;
}

.stub1 {
    line-height: 300px;
    font-size: 24px;
    background-color: #eae4e4;
}

.stub2 {
    height: 1000px;
}

.circle {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
    background-color: #e5e5e5;
}

.circleWrapper {
    position: relative;
    left: -12px;
}

.message {
    position: absolute;
    top: 20%;
    left: 5%;
    min-width: 150px;
    font-weight: bold;
    color: black;
}

/* time current */

.timelineContent {
    /* border: 1px solid red; */
    position: relative;
    top: -4rem;
    max-width: 700px;
    margin-left: 5rem;
}

.timelineContent>h3,
.timelineContent>h1,
.timelineContent>p {
    color: black;
}

.timelineContent>p {
    position: relative;
    top: -2.5rem;
}

.contentDate {
    display: inline;
    position: relative;
    left: -18rem;
    top: 0.5rem;
}